import React, {useState, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import Alert from '@frontend/ui-kit/Components/Alert';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Label from '@frontend/ui-kit/Components/Label';
import Select from '@frontend/ui-kit/Components/Select';
import Text from '@frontend/ui-kit/Components/Text';
import EligibilityForm from './EligibilityForm';
import BiometricsDataStorageForm from './BiometricsDataStorageForm';
import BiometricsEventAutomationForm from './BiometricsEventAutomationForm';
import InsuranceVerificationDataStorageForm from './InsuranceVerificationDataStorageForm';
import InsuranceVerificationEventAutomationForm from './InsuranceVerificationEventAutomationForm';
import PreCertificationDataStorageForm from './PreCertificationDataStorageForm';
import PreCertificationEventAutomationForm from './PreCertificationEventAutomationForm';
import MedicalClaimsDataStorageForm from './MedicalClaimsDataStorageForm';
import MedicalClaimsEventAutomationForm from './MedicalClaimsEventAutomationForm';
import PharmacyClaimsForm from './PharmacyClaimsForm';
import ProcedureDataStorageForm from './ProcedureDataStorageForm';
import ProcedureEventAutomationForm from './ProcedureEventAutomationForm';
import UtilizationDataStorageForm from './UtilizationDataStorageForm';
import UtilizationEventAutomationForm from './UtilizationEventAutomationForm';
import ConsultsDataStorageForm from './ConsultsDataStorageForm';
import ConsultsEventAutomationForm from './ConsultsEventAutomationForm';
import CaseManagementEventAutomationForm from './CaseManagementEventAutomationForm';
import CaseManagementDataStorageForm from './CaseManagementDataStorageForm';

import Section from './Section';
import {getImportConfigUpdateSuccess} from '../../../selectors/importConfigs';
import {getLoaderStatus} from '../../../selectors/shared';
import {initImportConfigForm} from '../../../actions/importConfigs';
import {
    DATA_STORAGE_INFORMATION,
    EVENT_AUTOMATION_INFORMATION,
    TPA_DATA_PURPOSE_OPTIONS,
    TPA_DATA_PURPOSES,
    TPA_FILE_TYPE_OPTIONS,
    TPA_FILE_TYPES
} from '../../../constants';
import './index.scss';

const ImportConfigForm = () => {
    const dispatch = useDispatch();
    const isSuccessfulSave = useSelector(getImportConfigUpdateSuccess);
    const isLoading = useSelector(getLoaderStatus);
    const {id: configId} = useParams();
    const [importConfigId] = useState(configId);
    const [tpaFileType, setTpaFileType] = useState(null);
    const [dataPurpose, setDataPurpose] = useState(null);
    const [partnerOptions, setPartnerOptions] = useState(null);
    const [isEdit, setIsEdit] = useState(true);

    useEffect(() => {
        (async () => {
            const {
                data: {
                    tpa_file_type: tpaFileType,
                    data_purpose: dataPurpose,
                    partnerOptions: {partners}
                }
            } = await dispatch(initImportConfigForm(importConfigId));

            const partnerList = partners.map(partner => {
                const {id, name} = partner;
                return {value: id, label: name};
            });

            setTpaFileType(tpaFileType);
            setDataPurpose(dataPurpose);
            setPartnerOptions(partnerList);
            setIsEdit(!!importConfigId);
        })();
    }, [dispatch, importConfigId]);

    const onTpaFileTypeChange = useCallback(async tpaFileType => {
        setTpaFileType(tpaFileType);
        setDataPurpose(TPA_DATA_PURPOSE_OPTIONS[tpaFileType][0].value);
    }, []);

    const onDataPurposeChange = useCallback(async dataPurpose => setDataPurpose(dataPurpose), []);

    const isEventAutomation = dataPurpose === TPA_DATA_PURPOSES.eventAutomation;
    const informationalText = isEventAutomation ? EVENT_AUTOMATION_INFORMATION : DATA_STORAGE_INFORMATION;

    return (
        <div className='import-config-form'>
            {isSuccessfulSave && !isLoading && (
                <Alert type='success' description='Updates Saved!' className='import-config-alert' />
            )}
            <Section header='Data Type' offset={0} sm={12}>
                <Row className='import-config-form-upper-margin'>
                    <Column sm={4}>
                        <Label label='File Type' type='select' isRequired>
                            <Select
                                placeholder='Select file type...'
                                options={TPA_FILE_TYPE_OPTIONS}
                                onChange={onTpaFileTypeChange}
                                value={tpaFileType}
                                disabled={isEdit}
                            />
                        </Label>
                        <Label label='Data Purpose' type='select' isRequired>
                            <Select
                                placeholder='Select data purpose...'
                                options={TPA_DATA_PURPOSE_OPTIONS[tpaFileType]}
                                onChange={onDataPurposeChange}
                                value={dataPurpose}
                                disabled={!tpaFileType || isEdit}
                            />
                        </Label>
                    </Column>
                    <Column sm={1} />
                    {dataPurpose && (
                        <Column sm={4} className='text-box-border'>
                            <Text>
                                <Text className='text-bold'>{informationalText.mainTopic}</Text>
                                <Text className='informational-paragraph'>{informationalText.body}</Text>
                                {informationalText.conclusion}
                            </Text>
                        </Column>
                    )}
                </Row>
            </Section>
            <Section displaySeparator={false} offset={0} sm={12}>
                {tpaFileType === TPA_FILE_TYPES.preCert && dataPurpose === TPA_DATA_PURPOSES.dataStorage && (
                    <PreCertificationDataStorageForm partnerOptions={partnerOptions} />
                )}
                {tpaFileType === TPA_FILE_TYPES.preCert && dataPurpose === TPA_DATA_PURPOSES.eventAutomation && (
                    <PreCertificationEventAutomationForm partnerOptions={partnerOptions} />
                )}
                {tpaFileType === TPA_FILE_TYPES.medicalClaims && dataPurpose === TPA_DATA_PURPOSES.dataStorage && (
                    <MedicalClaimsDataStorageForm partnerOptions={partnerOptions} />
                )}
                {tpaFileType === TPA_FILE_TYPES.medicalClaims && dataPurpose === TPA_DATA_PURPOSES.eventAutomation && (
                    <MedicalClaimsEventAutomationForm partnerOptions={partnerOptions} />
                )}
                {tpaFileType === TPA_FILE_TYPES.pharmacyClaims && (
                    <PharmacyClaimsForm partnerOptions={partnerOptions} />
                )}
                {tpaFileType === TPA_FILE_TYPES.eligibility && <EligibilityForm partnerOptions={partnerOptions} />}
                {tpaFileType === TPA_FILE_TYPES.insuranceVerification &&
                    dataPurpose === TPA_DATA_PURPOSES.dataStorage && (
                        <InsuranceVerificationDataStorageForm partnerOptions={partnerOptions} />
                    )}
                {tpaFileType === TPA_FILE_TYPES.insuranceVerification &&
                    dataPurpose === TPA_DATA_PURPOSES.eventAutomation && (
                        <InsuranceVerificationEventAutomationForm partnerOptions={partnerOptions} />
                    )}
                {tpaFileType === TPA_FILE_TYPES.consults && dataPurpose === TPA_DATA_PURPOSES.dataStorage && (
                    <ConsultsDataStorageForm partnerOptions={partnerOptions} />
                )}
                {tpaFileType === TPA_FILE_TYPES.consults && dataPurpose === TPA_DATA_PURPOSES.eventAutomation && (
                    <ConsultsEventAutomationForm partnerOptions={partnerOptions} />
                )}
                {tpaFileType === TPA_FILE_TYPES.caseManagement && dataPurpose === TPA_DATA_PURPOSES.dataStorage && (
                    <CaseManagementDataStorageForm partnerOptions={partnerOptions} />
                )}
                {tpaFileType === TPA_FILE_TYPES.caseManagement && dataPurpose === TPA_DATA_PURPOSES.eventAutomation && (
                    <CaseManagementEventAutomationForm partnerOptions={partnerOptions} />
                )}
                {tpaFileType === TPA_FILE_TYPES.utilization && dataPurpose === TPA_DATA_PURPOSES.dataStorage && (
                    <UtilizationDataStorageForm partnerOptions={partnerOptions} />
                )}
                {tpaFileType === TPA_FILE_TYPES.utilization && dataPurpose === TPA_DATA_PURPOSES.eventAutomation && (
                    <UtilizationEventAutomationForm partnerOptions={partnerOptions} />
                )}
                {tpaFileType === TPA_FILE_TYPES.procedure && dataPurpose === TPA_DATA_PURPOSES.dataStorage && (
                    <ProcedureDataStorageForm partnerOptions={partnerOptions} />
                )}
                {tpaFileType === TPA_FILE_TYPES.procedure && dataPurpose === TPA_DATA_PURPOSES.eventAutomation && (
                    <ProcedureEventAutomationForm partnerOptions={partnerOptions} />
                )}
                {tpaFileType === TPA_FILE_TYPES.biometrics && dataPurpose === TPA_DATA_PURPOSES.dataStorage && (
                    <BiometricsDataStorageForm partnerOptions={partnerOptions} />
                )}
                {tpaFileType === TPA_FILE_TYPES.biometrics && dataPurpose === TPA_DATA_PURPOSES.eventAutomation && (
                    <BiometricsEventAutomationForm partnerOptions={partnerOptions} />
                )}
            </Section>
        </div>
    );
};

export {ImportConfigForm as TestableImportConfigForm};
export default React.memo(ImportConfigForm);
